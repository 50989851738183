<template>
  <div class="container">
    <md-card class="dash-content">
      <div class="header-wrapper">
        <PageHeader title="Manage Affiliate" />
        <div class="loader">
          <Wave
            v-if="loading || updating || deleting"
            width="50px"
            color="#d9534f"
          />
        </div>
      </div>
      <md-table
        v-model="affiliates.mdData"
        md-card
        md-sort="title"
        md-sort-order="asc"
      >
        <md-table-toolbar class="mb-4">
          <div class="md-toolbar-section-start">
            <md-button
              class="md-primary button-icon"
              :disabled="exporting"
              @click="exportExcel"
            >
              Download Excel
              <img src="@/assets/svg/excel.svg" />
            </md-button>
          </div>
          <div class="toolbar-filter-wrapper">
            <md-field md-clearable class="md-toolbar-section-end">
              <md-input placeholder="Search by name..." v-model="search" />
            </md-field>
            <md-menu md-size="medium" md-align-trigger>
              <md-button
                md-menu-trigger
                class="md-primary button-icon"
                disabled
              >
                Filter
                <img src="@/assets/svg/filter.svg" />
              </md-button>

              <md-menu-content>
                <md-menu-item>Approved</md-menu-item>
                <md-menu-item>Pending</md-menu-item>
              </md-menu-content>
            </md-menu>
          </div>
        </md-table-toolbar>
        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell md-label="S/N" md-numeric>{{ item.id }}</md-table-cell>
          <md-table-cell md-label="Name">{{
            item.user ? item.user.name : "--"
          }}</md-table-cell>
          <md-table-cell md-label="Email">{{
            item.user ? item.user.email : "--"
          }}</md-table-cell>
          <md-table-cell md-label="Phone number"
            >{{ item.user ? item.user.phone_ext : "--"
            }}{{ item.user ? item.user.phone : "--" }}</md-table-cell
          >
          <md-table-cell md-label="Date Created">{{
            item.created_at | formatDate
          }}</md-table-cell>
          <!-- <md-table-cell md-label="code">{{
            item.tracking_code
          }}</md-table-cell> -->

          <!-- <md-table-cell md-label="Registered By">
            <div class="badge yellow">
              {{
                item.register
                  ? item.register.user.first_name +
                    " " +
                    item.register.user.last_name
                  : "User"
              }}
            </div>
          </md-table-cell> -->
          <md-table-cell md-label="Status">
            <div
              class="badge"
              :class="{
                success: item.approval_status == 'approved',
                danger: item.approval_status == 'disapproved',
              }"
            >
              {{ item.approval_status }}
            </div></md-table-cell
          >
          <md-table-cell md-label="Actions">
            <md-menu md-size="medium" md-align-trigger>
              <md-button :disabled="updating" md-menu-trigger class="bggreen"
                >Menu</md-button
              >

              <md-menu-content>
                <md-menu-item @click="setAffiliate(item)">Preview</md-menu-item>
                <md-menu-item
                  style="text-transform: initial; font-size: 16px"
                  :to="`/affiliate-management/${item.user_id}/sales`"
                >
                  <a style="text-transform: initial; font-size: 16px">Sales</a>
                </md-menu-item>
                <div v-if="hasPermission">
                  <md-menu-item
                    v-if="
                      ['disapproved', 'pending'].includes(item.approval_status)
                    "
                    @click="changeAffiliateStatus(item.id, 'approved')"
                    >Approve</md-menu-item
                  >
                  <md-menu-item
                    v-if="
                      ['approved', 'pending'].includes(item.approval_status)
                    "
                    @click="changeAffiliateStatus(item.id, 'disapproved')"
                    >Disapprove</md-menu-item
                  >
                  <!-- <md-menu-item
                    v-if="
                      ['disapproved', 'pending'].includes(item.approval_status)
                    "
                    @click="changeAffiliateStatus(item.id, 'approved')"
                    >Approve</md-menu-item
                  >
                  <md-menu-item
                    v-if="
                      ['approved', 'pending'].includes(item.approval_status)
                    "
                    @click="changeAffiliateStatus(item.id, 'disapproved')"
                    >Disapprove</md-menu-item
                  >
                  <md-menu-item>Delete </md-menu-item> -->
                </div>
              </md-menu-content>
            </md-menu>
          </md-table-cell>
        </md-table-row>
      </md-table>
      <span v-if="loading" class="b-loading">Loading...</span>
      <pagination
        v-model="affiliates.mdPage"
        :records="affiliates.mdCount"
        :per-page="affiliates.mdPerPage"
        @paginate="updatePagination"
      />
    </md-card>

    <md-dialog :md-active.sync="show">
      <md-dialog-title>Affiliate Details</md-dialog-title>
      <div class="cancel-modal">
        <i class="material-icons" @click="show = false">close</i>
      </div>

      <div class="modal-contain" v-if="affiliate">
        <div class="item">
          <strong>State</strong>
          <span v-html="affiliate.state"> </span>
        </div>
        <div class="item">
          <strong>Country</strong>
          <span> {{ affiliate.country }}</span>
        </div>

        <div class="item">
          <strong>Clicks</strong>
          <span> {{ affiliate.clicks }}</span>
        </div>

        <!-- <div class="item">
          <strong>Purchase</strong>
          <span> {{ affiliate.purchase }}</span>
        </div> -->

        <div class="item">
          <strong>Date Created</strong>
          <span> {{ affiliate.created_at | formatDate }}</span>
        </div>

        <div class="item">
          <strong>Approval Status</strong>
          <div
            class="badge"
            :class="{
              success: affiliate.approval_status == 'approved',
              danger: affiliate.approval_status == 'disapproved',
            }"
          >
            {{ affiliate.approval_status }}
          </div>
        </div>

        <div class="item">
          <strong>Tracking code</strong>
          <span> {{ affiliate.tracking_code }}</span>
        </div>

        <hr />
        <div>
          <h4>User information</h4>
        </div>
        <div class="item">
          <strong>Fullname</strong>
          <span> {{ affiliate.user.name }}</span>
        </div>

        <div class="item">
          <strong>Email Address</strong>
          <span> {{ affiliate.user.email }}</span>
        </div>

        <div class="item">
          <strong>Phone Number</strong>
          <a :href="`tel:${affiliate.user.phone_ext + affiliate.user.phone}`">
            {{ affiliate.user.phone_ext + affiliate.user.phone }}</a
          >
        </div>
      </div>
    </md-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("affiliate");
export default {
  name: "affiliate",
  data() {
    return {
      updating: false,
      deleting: false,
      exporting: false,
      search: "",
      show: false,
      time: null,
      affiliate: null,
      showS: false,
      multi_services: [],
      checkAllServices: false,
      approval_status: "",
    };
  },
  watch: {
    search(val) {
      this.waitForIt(val);
    },
    handyman(val) {
      let data = [];
      this.multi_services = [];
      this.checkAllServices = false;

      val.services.map((service) => {
        if (service.pivot.approval_status == "approved") {
          data.push(service.pivot.id);
        }
      });

      this.multi_services = data;
    },
  },

  methods: {
    ...mapActions(["getAffiliates", "updateAffiliateStatus"]),
    async updatePagination(page) {
      let data = { page };
      if (this.search) data.keyword = this.search;
      await this.getAffiliate(data);
    },
    async changeAffiliateStatus(id, status) {
      try {
        this.updating = true;
        await this.updateAffiliateStatus({ approval_status: status, id });
        this.updating = false;
        this.toast("success", "Affiliate Status Updated ");
      } catch (err) {
        this.updating = false;
        return Promise.reject(err);
      }
    },
    async exportExcel() {
      this.exporting = true;
      await this.$store.dispatch("auth/export", { model: "affiliate" });
      this.exporting = false;
    },
    async submitSearch() {
      await this.getAffiliate({ keyword: this.search });
    },
    async submitServices() {
      this.updating = true;
      var data = new FormData();
      if (this.approval_status == "") return;
      data.append("approval_status", this.approval_status);
      data.append("services", this.multi_services);
      data.append("_method", "PATCH");

      await this.updateServiceStatus(data);
      this.updating = false;
      this.showS = false;
      this.toast("success", "Status updated");
    },

    setAffiliate(affiliate) {
      this.show = true;
      this.affiliate = affiliate;
    },
    waitForIt(val) {
      if (this.time) {
        clearTimeout(this.time);
      }
      this.time = setTimeout(() => this.submitSearch(val), 1000);
    },
  },
  created() {},
  mounted() {
    this.getAffiliates();
  },
  computed: {
    ...mapGetters(["affiliates", "loading"]),
    hasPermission() {
      return this.$store.getters["auth/has_module_permission"]("crud");
    },
  },
};
</script>

<style lang="scss" scoped>
.md-table-cell {
  width: 0% !important;
  padding: 0rem 0.8rem;
}

.md-table + .md-table {
  margin-top: 16px;

  thead {
    height: 50px !important;
  }
}

.badge {
  background: #f3f3f3;

  &.success {
    background: green;
  }

  &.danger {
    background: red;
  }
}

.s-con {
  .head,
  .body .s-items {
    display: flex;
    align-items: center;

    > div {
      width: calc(100% / 3);
    }
  }
  .head {
    font-weight: 700;
  }

  .body .s-items .s-item {
    padding: 10px 0px;
  }
}
</style>
